var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-card-title',{staticClass:"headline lighten-2",attrs:{"primary-title":""}},[_vm._v(" Max Field ")]),_c('v-card-subtitle',[_vm._v(" Niantic CN Version Frontend v0.65 ")]),_vm._v(" 本前端程序由 NIACNET 高性能实验室维护，使用 GPLv3 方式授权。 ")],1),_c('v-card-text',[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("用户使用条款")]),_c('v-expansion-panel-content',[_vm._v(" 1. 想好再提交，乱提交 ban IP"),_c('br'),_vm._v(" 2. 对实际操作中出现的状况不负责"),_c('br'),_vm._v(" 3. 本站开源，欢迎 PR ")])],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("隐私权政策")]),_c('v-expansion-panel-content',[_vm._v(" 1. 维护者懒得去看您提交了啥"),_c('br'),_vm._v(" 2. 任务 ID 存在浏览器本地"),_c('br'),_vm._v(" 3. 运行结果一天之后自动删库 ")])],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("鸣谢")]),_c('v-expansion-panel-content',[_vm._v(" 1. "),_c('b',[_vm._v("Niantic. Inc.")]),_vm._v("，因为本站给他们家的产品服务的"),_c('br'),_vm._v(" 2. "),_c('b',[_vm._v("Maxfield 原维护者")]),_vm._v("，因为本站直接调了他们的库来用"),_c('br'),_vm._v(" 3. "),_c('b',[_vm._v("NIACNET")]),_vm._v("，因为它给本站白嫖了计算资源 ")])],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.open_link('https://gitlab.com/UESTC-Ingress/maxfield-frontend')}}},on),[_c('v-icon',[_vm._v("mdi-xml")])],1)]}}])},[_c('span',[_vm._v("前端代码")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.open_link('https://gitlab.com/UESTC-Ingress/maxfield-api')}}},on),[_c('v-icon',[_vm._v("mdi-code-braces")])],1)]}}])},[_c('span',[_vm._v("API 代码")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.open_link('https://gitlab.com/UESTC-Ingress/maxfield-worker')}}},on),[_c('v-icon',[_vm._v("mdi-code-brackets")])],1)]}}])},[_c('span',[_vm._v("worker 代码")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.open_link(
                'https://hub.docker.com/repository/docker/yukarichiba/maxfield-worker'
              )}}},on),[_c('v-icon',[_vm._v("mdi-docker")])],1)]}}])},[_c('span',[_vm._v("Docker worker 代码")])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 关闭 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }